import * as React from 'react'
import Link from 'gatsby-link'

import { navigate, graphql } from 'gatsby'

interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
}

const IndexPage = (props: IndexPageProps) => {
  React.useEffect(() => {
    navigate('/app')
  })
  return <></>
}

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default IndexPage
